.externalContent p,
blockquote,
li {
    font-size: 18px;
    font-family: 'GTAMERICA_LIGHT', sans-serif;
}

.externalContent h1 {
    font-size: 32px;
    margin-top: 60px;
}

.externalContent h2 {
    font-size: 32px;
    margin-top: 60px;
}

.externalContent h3 {
    font-size: 24px;
    margin-top: 60px;
    margin-bottom: 20px;
}

.externalContent h4 {
    font-size: 24px;
    margin-top: 60px;
    margin-bottom: 20px;
}

.externalContent p {
    margin-top: 20px;
}

.externalContent b {
    font-weight: 900;
    font-family: 'GTAMERICA_MEDIUM';
}

.externalContent li {
    margin-top: 8px;
}

.externalContent span {
    font-weight: 300 !important;
}

.externalContent ul {
    list-style-type: disc;
    list-style: disc;
    padding-left: 10px;
}

.externalContent ul > li > p {
    margin-top: 10px;
}
