.rangeslider {
    box-shadow: none;
}

.rangeslider__fill {
    background-color: #818cf8 !important;
    box-shadow: none !important;
}

.rangeslider__handle {
    background: #6366f1 !important;
    border: none !important;
}

.rangeslider__handle:after {
    content: none !important;
    background-color: #6366f1 !important;
}

.rangeslider__handle:focus {
    outline: none !important;
}
